body {
	margin: 0;
	font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
		'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
		sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	margin: 0;
	font-family: 'Roboto', sans-serif;
	font-size: 16px;
	line-height: 24px;
	color: #ffffff;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
		monospace;
}
::placeholder {
	color: #737283 !important;
}

/* width */
::-webkit-scrollbar {
	width: 8px;
	background: #070d1e;
	border-radius: 8px;
	height: 8px;
}

/* Track */
::-webkit-scrollbar-track {
	box-shadow: inset 0 0 5px grey;
	border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
	background: rgba(28, 132, 228, 0.5);
	border-radius: 10px;
	height: 8px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
	background: rgba(28, 132, 228, 0.5);
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
	/* display: none; <- Crashes Chrome on hover */
	-webkit-appearance: none;
	margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}

input[type='number'] {
	-moz-appearance: textfield; /* Firefox */
}
